.stepOne {
  background-color: #709176;

  width: 100vw;
  position: absolute;
}
.signUpSellerFormStepOne {
  width: 75%;
  margin: 0 auto;
  input {
    width: 100%;
    height: 35px;
    border-radius: 10px;
    text-align: center;
    border: none;
  }

  input::placeholder {
    text-align: center;
  }
}

.brandOption {
  background-color: #fff;
  display: flex;
  height: 35px;

  p {
    color: #000;
    font-size: 0.8em;
  }
}

.test {
  div {
    border-radius: 10px;
    margin-top: 1em;
    height: 35px;
    width: 100%;
    background-color: #fff;
    border: 2px solid #fff;
    text-align: center;

    label {
      color: #000;
      font-size: 0.8em;
      height: 40px;
      margin: 0 auto;
    }
    input {
      position: fixed;
      opacity: 0;
      width: 100%;
    }
  }
}

.test input[type="radio"] + label {
  display: flex;
  height: 40px;
  margin-top: -4px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.test input[type="radio"]:checked + label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  height: 35px;
  border-radius: 10px;
  padding: 0.1em;
  position: relative;
  bottom: 0.2em;
  width: 100%;
  right: 3px;
  border: 2px solid #ff9900;
  margin: 0 auto;
}

#nextBut1 {
  width: 100%;
  height: 60px;
  background-color: #ff9900;
  color: white;
  font-size: 2.2em;
  font-weight: 700;
}

/* 
#container {
  color: #000;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  margin: 0 auto;
  display: flex;
}

#flip {
  height: 50px;
  overflow: hidden;
}

#flip > div > div {
  color: #fff;
  height: 45px;
  display: inline-block;
}

#flip div:first-child {
  animation: show 5s linear infinite;
}

#flip div div {
  color: #dc143c;
}
#flip div:first-child div {
  color: yellow;
}
#flip div:last-child div {
  color: blue;
}

@keyframes show {
  0% {
    margin-top: -270px;
  }
  5% {
    margin-top: -180px;
  }
  33% {
    margin-top: -180px;
  }
  38% {
    margin-top: -90px;
  }
  66% {
    margin-top: -90px;
  }
  71% {
    margin-top: 0px;
  }
  99.99% {
    margin-top: 0px;
  }
  100% {
    margin-top: -270px;
  }
}
 */

#test {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wordCarousel {
  font-size: 18px;
  color: #000;
  width: 390px;
  text-align: left;
  margin-left: 6em;
  font-family: "Inter", sans-serif;
  margin-bottom: -1em;
  div {
    width: 90%;
    overflow: hidden;
    height: 65px;
    position: relative;
    bottom: 1.2em;
    left: 1.5em;
    li {
      height: 45px;
      margin-bottom: 45px;
      display: block;
      text-align: left;
    }
  }
}

.workLoad {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 0.8em;
  &__item {
    margin-bottom: 1em;
    background-color: #fff;
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 2px solid #ffffff;
  }
  .active {
    border: 2px solid #ff9900;
  }
}

.linkBackArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 35px;
  height: 35px;
  border: 5px solid #000;
  color: #000;
  border-radius: 50%;
  margin: 0 auto;
  padding-left: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-top: -1.5em;
  margin-bottom: 2.33em;
}

.flip2 {
  animation: flip2 6s cubic-bezier(0.23, 1, 0.32, 1.2) infinite;
}
.flip3 {
  animation: flip3 8s cubic-bezier(0.23, 1, 0.32, 1.2) infinite;
}
.flip4 {
  animation: flip4 10s cubic-bezier(0.23, 1, 0.32, 1.2) infinite;
}
.flip5 {
  animation: flip5 12s cubic-bezier(0.23, 1, 0.32, 1.2) infinite;
}

@keyframes flip2 {
  0% {
    margin-top: -180px;
  }
  5% {
    margin-top: -90px;
  }
  50% {
    margin-top: -90px;
  }
  55% {
    margin-top: 0px;
  }
  99.99% {
    margin-top: 0px;
  }
  100% {
    margin-top: -180px;
  }
}

@keyframes flip3 {
  0% {
    margin-top: -270px;
  }
  5% {
    margin-top: -180px;
  }
  33% {
    margin-top: -180px;
  }
  38% {
    margin-top: -90px;
  }
  66% {
    margin-top: -90px;
  }
  71% {
    margin-top: 0px;
  }
  99.99% {
    margin-top: 0px;
  }
  100% {
    margin-top: -270px;
  }
}

@keyframes flip4 {
  0% {
    margin-top: -360px;
  }
  5% {
    margin-top: -270px;
  }
  25% {
    margin-top: -270px;
  }
  30% {
    margin-top: -180px;
  }
  50% {
    margin-top: -180px;
  }
  55% {
    margin-top: -90px;
  }
  75% {
    margin-top: -90px;
  }
  80% {
    margin-top: 0px;
  }
  99.99% {
    margin-top: 0px;
  }
  100% {
    margin-top: -360px;
  }
}

@keyframes flip5 {
  0% {
    margin-top: -450px;
  }
  5% {
    margin-top: -360px;
  }
  20% {
    margin-top: -360px;
  }
  25% {
    margin-top: -270px;
  }
  40% {
    margin-top: -270px;
  }
  45% {
    margin-top: -180px;
  }
  60% {
    margin-top: -180px;
  }
  65% {
    margin-top: -90px;
  }
  80% {
    margin-top: -90px;
  }
  85% {
    margin-top: 0px;
  }
  99.99% {
    margin-top: 0px;
  }
  100% {
    margin-top: -450px;
  }
}
