#sideNav {
  width: 100%;

  ul {
    display: flex;
    padding: 0;
    list-style: none;
    justify-content: space-evenly;
    li {
      a {
        color: black;
        text-decoration: none;
      }
    }
  }
}
