.productCard {
  width: 41%;
  margin-right: 1.2em;
  z-index: 10;
  .productInfo {
    line-height: 0.5;
    font-size: 1.2em;
  }

  a {
    color: white;
    text-decoration: none;
    .imgPart {
      display: grid;
      grid-template-columns: 1;
      grid-template-rows: 1;
      img {
        grid-area: 1/1/1/1;
      }
      .heart {
        grid-area: 1/1/1/1;
        z-index: 1;
        text-align: end;
      }
    }
  }
}
