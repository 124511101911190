.sellers_topImg {
  position: fixed;
  top: 105px;
  z-index: -1;
}

.sellersList {
  margin-top: 15em;
  z-index: 21;
  background-color: white;
}
