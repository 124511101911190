.stepTwo {
  background-color: #709176;
  height: 100vh;
  width: 100vw;
  position: absolute;
}

input {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 85%;
  background-color: white;
  margin-bottom: 1em;
  border-radius: 10px;
  margin: 1em auto;
}
.cvr {
  width: 85%;
  .active {
    border: #000 solid 2px;
  }
  div {
    border: #fff 2px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 100%;
    background-color: white;
    margin-bottom: 1em;
    border-radius: 10px;
    label {
      font-size: 0.8em;
      color: #000;
      margin: 0;
    }
    input {
      position: fixed;
      opacity: 0;
      width: 85%;
      text-align: center;
      height: 35px;
    }
  }

  /* input[type="radio"]:checked + label {
    border: 2px solid #000;
    display: block;
    width: 100%;
    height: 35px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  margin: 0 auto;
  /* 
  input[type="radio"]:checked + label {
  } */
}
h2 {
  color: #fff;
  font-size: 1.2em;
  text-align: center;
}

label {
  font-size: 1em;
  color: white;
  margin-left: 2.5em;
}

#nextBut2 {
  max-width: 85%;
  width: 100%;
  height: 60px;
  background-color: #ff9900;
  color: white;
  font-size: 2.2em;
  font-weight: 700;
}
