.signUpPage{
    
    .signUpCoise{
        display: flex;
        justify-content: space-evenly;

        a{
            color: #000;
            text-decoration: none;
        }
    }
}