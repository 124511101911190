.sellerProfile {
  color: black;
  display: flex;
  justify-content: space-between;
  margin: 0em 1em;

  margin-top: 2em;
  .sellerStats {
    display: grid;
    grid-template-columns: 3;
    grid-template-rows: 2;
    grid-gap: 1em;
    width: 100%;
    margin-left: 2em;
    font-weight: 500;

    button {
      grid-area: 1/2/2/4;
      max-width: 126px;
      padding: 0.5em 2em;
      border-radius: 20px;
      background-color: white;
      border: 3px solid #ff9900;
    }
    .profileStats {
      grid-area: 2/1/2/4;
      display: flex;
      justify-content: space-between;
      margin: 1em 0em;
      text-align: center;
    }
  }
}

.sellerInfo {
  margin: 0 1em;

  a {
    font-weight: 500;
    font-size: 24px;
    color: #000;
    text-decoration: none;
  }

  .sellerDescription {
    background-color: rgba(255, 153, 0, 0.25);
    padding: 1em;
    margin-top: 1.5em;
  }
}

.sellerProducts {
  padding: 1em;

  &__itemList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
