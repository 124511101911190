.stepThree {
  background-color: #709176;
  height: 100vh;
  width: 100vw;
  position: absolute;
}

#city {
  display: flex;
  align-items: center;
  color: #000;
  background-color: white;
  width: 85%;
  border-radius: 10px;
  height: 35px;
  margin: 0 auto;
}
#phoneDiv {
  max-width: 85%;
  margin: 0 auto;
  margin-top: 10px;
  label {
    margin: 0 0;
  }
  div {
    input {
      padding-left: 4em;
    }
  }
  
}
