.signUpSellerFormStepOne {
  width: 75%;
  margin: 0 auto;
  input {
    width: 100%;
    height: 35px;
    border-radius: 10px;
    text-align: center;
    border: none;
  }

  input::placeholder {
    text-align: center;
  }
}

.brandOption {
  background-color: #fff;
  display: flex;
  height: 35px;

  p {
    color: #000;
    font-size: 0.8em;
  }
}

.test {
  div {
    border-radius: 10px;
    margin-top: 1em;
    height: 35px;
    width: 100%;
    background-color: #fff;
    border: 2px solid #fff;
    label {
      font-size: 0.9em;
    }
    input {
      position: fixed;
      opacity: 0;
    }
  }
}

.test input[type="radio"]:checked + label {
  display: block;
  height: 33px;
  border-radius: 10px;
  padding: 0.1em;
  position: relative;
  bottom: 0.1em;
  width: 100%;
  right: 3px;
  border: 2px solid orange;
}
