#navbar {
  background-color: white;
  padding: 0.1rem;
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 50px;
  width: 99%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0 0 10px 10px;
  ul {
    padding: 0;
    display: flex;
    list-style: none;
    justify-content: space-evenly;
    li {
      a {
        color: black;
        text-decoration: none;
      }
    }
  }
}

// Language: scss
