.productPage {
  display: flex;
  &__info {
    padding: 1.2em;
    background-color: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
    border-radius: 15px 15px 0px 0px;
    z-index: 1;
    margin-top: 25em;

    .productButtons {
      display: flex;
      flex-direction: column;
      gap: 1em;
      padding-bottom: 3em;
      button {
        height: 40px;
        font-size: 22px;
        border-radius: 20px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
      .buyBut {
        background: rgba(255, 153, 0, 0.85);
        color: white;
      }
      .favBut {
        border: 2px solid #000000;
        background: white;
      }
      .folders {
        div {
          h3 {
            border: 1px solid #000000;
          }
          background-color: #000000;
        }
      }
    }
  }
  &__image {
    position: fixed;
    top: 50px;
    left: 0px;
    right: 0;
    img {
      width: 100%;
      height: auto;
    }
  }
}
